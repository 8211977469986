.overflow-scroll {
  overflow-x: auto;
}
.item-table {
  width: 100%;
}

.item-table tbody td {
  min-width: 100px;
  max-width: 150px;
  padding: 10px;
}
.item-table tbody td:last-child {
  min-width: fit-content;
  padding: 10px;
}
.item-table tfoot {
  border-top: 2px solid #ccc;
}
.item-table tfoot th {
  padding: 10px;
  text-align: center;
}
.item-table tfoot th:first-child {
    text-align: left;
}
