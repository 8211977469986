$theme-color: #376fd0;
// $primary-color: #233044;
$white-color: #ffffff;
$bg-color: #f7f9fc;

iframe {
  pointer-events: none;
}
.pdfViewer{
  iframe {
   pointer-events: all;
    border: 1px solid #ccc;
  }
}

.cursor-p {
  cursor: pointer;
}

.table-responsive {
  overflow: auto;
}

.f-14 {
  font-size: 14px;
}

.sidebar[data-color="black"],
.off-canvas-sidebar[data-color="black"] {
  &::after {
    background: $theme-color;
  }
}

.sidebar {
  overflow: hidden;

  .nav {
    li {
      a {
        margin: 0;
        opacity: 1;
        text-transform: none;

        p {
          font-size: 14px;
        }
      }

      &.active,
      &:hover {
        >a {
          color: $white-color !important;
          opacity: 1;
          background-color: darken($theme-color, 3%);

          i {
            color: $white-color !important;
            opacity: 1;
          }
        }

        >a[data-toggle="collapse"] {
          color: $white-color;
          opacity: 1;

          i {
            color: $white-color;
            opacity: 1;
          }

          ~ {
            div {
              >ul {
                >li.active {
                  .sidebar-mini-icon {
                    color: $white-color;
                    opacity: 1;
                  }

                  >a {
                    color: $white-color;
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.off-canvas-sidebar {
  .nav {
    li.active {
      >a {
        color: $white-color;
        opacity: 1;

        i {
          color: $white-color;
          opacity: 1;
        }
      }

      >a[data-toggle="collapse"] {
        color: $white-color;
        opacity: 1;

        i {
          color: $white-color;
          opacity: 1;
        }

        ~ {
          div {
            >ul {
              >li.active {
                .sidebar-mini-icon {
                  color: $white-color;
                  opacity: 1;
                }

                >a {
                  color: $white-color;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
    }
  }
}

.sidebar {
  .sidebar-wrapper {
    li.active {
      >a {

        &::after,
        &::before {
          display: none !important;
        }
      }
    }
  }
}

.sidebar {
  .logo {
    background-color: darken($theme-color, 3%);
    padding: 5px 0;

    a {
      font-weight: bold;
    }

    &::after {
      content: none;
    }
  }
}

.off-canvas-sidebar {
  .logo {
    &::after {
      content: none;
    }
  }
}

.login-wrap {
  width: 460px;
}

.main-panel {
  background-color: $bg-color;
}

.no-transition {
  transition: none !important;
}


.filter-container {
  position: absolute;
  right: 5px;
  top: 100%;
  width: 500px;
  z-index: 9;
}

.MuiTablePagination-root p {
  margin: 0;
}

.nav-tabs {
  background: $theme-color;
  z-index: 1;

  .nav-link {
    border: none !important;
    background: transparent;
    color: #fff;
    padding: 12px 15px;
    margin: 0;

    &.active {
      background-color: darken($theme-color, 10%);
      color: #fff;
      border-radius: 0;
    }
  }
}

.tab-content {
  border: 1px solid #ddd;
  margin-top: -1px;
}

.card label {
  font-size: 16px;
}

.modal-90w {
  width: 90%;
  max-width: 1300px !important;
}

.form-control {
  border: 1px solid #c1bebe;
  padding: 9px 22px;

  &:hover {
    border: 1px solid #000;

  }
}


.btn {

  font-weight: 400;
  font-size: 14px;
  text-transform: none;

}



.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease
}

@media (prefers-reduced-motion:reduce) {
  .accordion-button {
    transition: none
  }
}

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .125)
}

.accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  transform: rotate(-180deg)
}

.accordion-button:after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform .2s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
  .accordion-button:after {
    transition: none
  }
}

.accordion-button:hover {
  z-index: 2;
}

.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
}

.accordion-header {
  margin-bottom: 0
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .125)
}

.accordion-item:first-of-type {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px)
}

.accordion-item:not(:first-of-type) {
  border-top: 0
}

.accordion-item:last-of-type {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px)
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem
}

.accordion-body {
  padding: 20px
}

.accordion-flush .accordion-collapse {
  border-width: 0
}

.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0
}

.accordion-flush .accordion-item:first-child {
  border-top: 0
}

.accordion-flush .accordion-item:last-child {
  border-bottom: 0
}

.accordion-flush .accordion-item .accordion-button {
  border-radius: 0
}

.table th,
.table td {
  vertical-align: middle;
}

.custom-table {
  thead {
    background-color: #ccd5e7;
  }

  tr {

    th:nth-child(9),
    th:nth-child(10),
    th:nth-child(11),
    th:nth-child(12),
    td:nth-child(9),
    td:nth-child(10),
    td:nth-child(11),
    td:nth-child(12) {
      background-color: rgba(229, 232, 243, 0.45);
    }
  }
}

.bg-lite-blue {
  background-color: #ccd5e7;
}

.form-group {
  .btn {
    padding: 9px 22px;
  }

}

.sidebar {
  .nav {
    a {
        border-bottom: 1px solid rgba(250, 250, 250, .5);
        padding: 8px 0 8px 20px !important;
        margin-left: 0px !important;
       p {
          
          display: inline-block;
          margin-left: 6px;
        }
    }

    .nav {
      margin: 0 0 0 20px;
      display: none;
      &.is-open{
        display: block;
      }
      a{
        background: url(../../img/arrow.png) no-repeat left 5px center;
        padding-left: 17px !important;
        margin-left: 23px !important;
      }
    }
  }
}

textarea.MuiInputBase-inputMultiline {
    height: 80px!important;
}
.MuiTable-root{
  tr{
    td,th{
      padding: 14px!important;
      .btn {
        margin-top: 0;
        margin-bottom: 0;
      } 
    }
  }
}
.custom-loader {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	background: rgba(0, 0, 0, 0.3);
	justify-content: center;
	display: flex;
	align-items: center;
}